import React, { Fragment, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { QuotesContext } from './Barchart/Context/Quotes'
import { BidsContext } from './Barchart/Context/Bids'
import PageHeader from './PageHeader'
import Cmdty from './Barchart/Cmdty'
//import Quotes from './Barchart/Quotes'
//import Bids from './Barchart/Bids'
import BarchartLegalDisclaimer from './Barchart/BarchartLegalDisclaimer'

const Grain = () => {


  const quotesCtx = useContext(QuotesContext)
  const bidsCtx = useContext(BidsContext)

  useEffect(() => {
    quotesCtx.startPolling()
    bidsCtx.startPolling()
    return () => {
      quotesCtx.stopPolling()
      bidsCtx.stopPolling()
    }
  }, []) // <-- Suck it, React. No. I'm not going to add quotesCtx to this array.

  const { quotes } = quotesCtx
  const cmdtyTypes = [...new Set(quotes.map(q => q.name))]

  return (
    <Fragment>
      <PageHeader title="Grain" />
      <div className="px-10 md:px-20 xl:px-40">
        <h2 className="text-green text-3xl mt-4 mb-3 border-b">A dependable partner for your grain needs</h2>
        <p>
          Our grain marketing knowledge and experience is here to help with hedging and risk management tactics
          to take your grain marketing to the next level.
        </p>
        <p className="mt-4">
          Both our <span className="font-bold">St. Joseph</span> and <span className="font-bold">Stanberry</span> facilities
          are being improved to increase space and time for your grain for the fall of 2020.
        </p>
        <p className="mt-4">
          <Link className="underline text-green hover:text-sage" to="/alerts">
            Manage your notifications
          </Link>
        </p>

        {/*
        Jeff, we need a better UI around Quotes and Bids. For example, maybe tabs to
        toggle between Quotes and Bids. But I really want to learn more from Tracey
        how people would LIKE to consume this data. All the competitor pages make
        these very separate views, but that seems wrong to me.
      */}

        {cmdtyTypes.map(cmdty => <Cmdty key={cmdty} cmdty={cmdty} />)}

        {/*

        <h1 className="mt-6 md:mt-10 px-6 py-2 text-2xl bg-gray-300">Quotes</h1>
        <Quotes />

        <h1 className="mt-6 md:mt-10 px-6 py-2 text-2xl bg-gray-300">Cash Bids</h1>
        <Bids />

*/}

        <BarchartLegalDisclaimer />

      </div>
    </Fragment>
  )
}

export default Grain