import React from 'react'

const textSize = title => {
  if (!title) return ''
  if (title.length > 30) { return 'text-md' }
  if (title.length > 20) { return 'text-xl' }
  return 'text-2xl'

}

const PageHeader = ({ title }) => (
  <div className="flex hero items-stretch text-white">
    <div className="md:flex-0 align-text-top bg-green">

    </div>
    <div className="pl-0 md:pl-20 xl:pl-40 flex-grow-1 w-full bg-green pt-6 pb-3">
      <h2 className={`text-2xl text-center md:text-left ${textSize(title)}`}>{title}</h2>
    </div>
    <div className="hidden md:inline-block bg-no-repeat bg-cover bg-center flex-0 content-end overflow-hidden" style={{ backgroundImage: 'url("/img/field.jpg")', maxWidth: 500, minWidth: 500, marginTop: -50, maxHeight: 150 }}>
      <img src="/img/img-over-b.svg" alt="" />
    </div>
    <div className="hidden md:inline-block md:flex-none md:w-20 xl:w-40">
    </div>
  </div>
)

export default PageHeader
