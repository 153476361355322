import React, { useReducer, createContext } from "react"
import {
  fetchWeather,
  DEFAULT_ZIPCODE,
} from '../'

const initialState = {
  weather: [],
  zipCode: DEFAULT_ZIPCODE,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_DATA':
      return {
        ...state,
        loading: false,
        weather: action.data,
      }

    case 'FETCH_STARTING':
      return {
        ...state,
        loading: true,
      }

    case 'SET_ZIPCODE':
      return {
        ...state,
        zipCode: action.zipCode,
      }

    default:
      return state
  }
}

const Context = createContext()

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    weather,
    loading,
  } = state

  state.getWeather = async (zipCode) => {
    let forceFetch = false
    if (zipCode !== state.zipCode) {
      dispatch({
        type: 'SET_ZIPCODE',
        zipCode,
      })
      forceFetch = true
    }
    let data = weather
    if (forceFetch || (!loading && !weather.length)) {
      dispatch({
        type: 'FETCH_STARTING',
      })
      const data = await fetchWeather(zipCode)
      dispatch({
        type: 'LOAD_DATA',
        data,
      })
    }
    return data
  }

  return (
    <Context.Provider value={{
      ...state,
    }}>
      {children}
    </Context.Provider>
  )
}

export {
  Provider as WeatherProvider,
  Context as WeatherContext,
}