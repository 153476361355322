import React, { useContext } from 'react'
import { QuotesContext } from './Context/Quotes'
import QuoteRow from './QuoteRow'

const QuotesCmdty = ({ cmdty }) => {

  const quotesCtx = useContext(QuotesContext)
  const quotes = quotesCtx.quotes.filter(q => q.name === cmdty)

  return (
    <div>
      {/* <h1 className="mt-4 text-xl">{cmdty}</h1> */}
      <table className="table-auto w-full">
        <thead className="bg-sage text-white">
          <tr className="text-left">
            <th className="px-4 py-2">Symbol</th>
            <th className="px-4 py-2">Last</th>
            <th className="px-4 py-2">Change</th>
            <th className="px-4 py-2">High</th>
            <th className="px-4 py-2">Low</th>
          </tr>
        </thead>
        <tbody>
          {quotes.map(quote => <QuoteRow key={quote.symbol} quote={quote} />)}
        </tbody>
      </table>
    </div>
  )

}

export default QuotesCmdty
