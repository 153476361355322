import React from 'react'


/*
    We are required to include the disclaimer below anywhere we show market data.
    We can some liberty with style, but mostly this has to stay intact.
    https://www.barchart.com/solutions/disclaimers
*/
const BarchartLegalDisclaimer = () => (
  <div className="pt-6 text-gray-600">
    <p>
      Copyright © 2020. All <a className="underline" target="_blank" rel="noopener noreferrer" href="https://www.barchartmarketdata.com/">market data</a> is provided by Barchart Solutions.
        </p>
    <p className="pt-4">
      Futures: at least 10 minute delayed. Information is provided 'as is' and solely for informational purposes,
      not for trading purposes or advice. To see all exchange delays and terms of use, please
          see <a className="underline" target="_blank" rel="noopener noreferrer" href="https://www.barchartmarketdata.com/terms">disclaimer</a>.
        </p>
    <div className="py-4">
      <a className="" target="_blank" rel="noopener noreferrer" href="https://www.cmegroup.com"><img src="/img/cme-transparent.png" alt="CME logo" /></a>
    </div>
  </div>
)

export default BarchartLegalDisclaimer
