import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import queryString from 'query-string'
import addDays from 'date-fns/addDays'
import addMonths from 'date-fns/addMonths'
import parse from 'date-fns/parse'
import slugify from 'slugify'

const API_KEY = '847747ba8b8a93c2bb934807ae2948db'
const BASE_URL = 'https://ondemand.websol.barchart.com'
const DEFAULT_ZIPCODE = '64489'
const SYMBOLS_TO_FETCH = {
  corn: ['ZC*1', 'ZC*2', 'ZC*3', 'ZC*4'],
  soybean: ['ZS*1', 'ZS*2', 'ZS*3', 'ZS*4'],
  wheat: ['KE*1', 'KE*2', 'KE*3', 'KE*4'],
}

// Strip out all null properties from an object
const stripNullProps = (obj) => Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== null))

const apiGet = async (service, params) => {
  try {
    const res = await fetch(`${BASE_URL}/${service}?apikey=${API_KEY}&${queryString.stringify(params, { arrayFormat: 'comma' })}`, {
      method: 'get',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    if (res.status >= 500) {
      this.onError(new Error(`API server error. ${res.status}`))
      return false;
    }
    const json = await res.json()
    return json.results
  } catch (error) {
    console.error(error)
    return false
  }
}

/*
// http://barchartondemand.com/api/getSymbolLookUp
const fetchSymbols = async keyword => {
  const data = await apiGet('getSymbolLookUp.json', {
    exchanges: 'CBOT',
    keyword,
    limit: 10,
  })
  return data
}

const fetchCornSymbols = async () => {
  const data = await fetchSymbols('corn')
  const symbols = data.filter(r => r.symbol.slice(0, 2) === 'ZC').slice(0, 4)
  return symbols
}

const fetchSoybeanSymbols = async () => {
  const data = await fetchSymbols('soybean')
  const symbols = data.filter(r => r.symbol.slice(0, 2) === 'ZS').slice(0, 4)
  return symbols
}

// Wheat lookup did not work. Using shortcut symbols now.
const fetchWheatSymbols = async () => {
  const data = await fetchSymbols('wheat')
  const symbols = data.filter(r => r.symbol.slice(0, 2) === 'KE').slice(0, 4)
  return symbols
}
*/

// http://barchartondemand.com/api/getQuote
const fetchQuotes = async symbols => {
  symbols = symbols || [
    ...SYMBOLS_TO_FETCH.corn,
    ...SYMBOLS_TO_FETCH.soybean,
    ...SYMBOLS_TO_FETCH.wheat,
  ]
  const data = await apiGet('getQuote.json', {
    symbols
  })
  return data
}

// http://barchartondemand.com/api/getNews
const fetchNews = async (limit = 100) => {
  let data = await apiGet('getNews.json', {
    // sources: ['NASS', 'USDA', 'SETZ', 'CMDTY_AGLITE', 'CMDTY_AG'],
    sources: ['NASS', 'USDA', 'SETZ', 'CMDTY_AGLITE'],
    category: 'futures',
    subCategory: 'grain',
    displayType: 'preview',
    images: true,
    maxRecords: limit + 3,  // Bug in their API
  })

  // Filter out "dupes". If headline starts with "USDA",
  // We probably get the story already from USDA source.
  data = data.filter(item => item.headline.slice(0, 4) !== 'USDA')

  // Add a slug to each story
  data.map(story => story.slug = slugify(story.headline))
  return data
}

// http://barchartondemand.com/api/getNews
const fetchNewsItem = async (storyId) => {
  const data = await apiGet('getNews.json', {
    storyId,
    displayType: 'full',
  })
  return data[0]
}

// https://www.barchart.com/ondemand/api/getWeather
const fetchWeather = async (zipCode = DEFAULT_ZIPCODE) => {
  const data = await apiGet('getWeather.json', {
    zipCode,
    weatherType: 'F',  // CC, F, MAP
    mapHeight: window.innerHeight - 50,
    mapWidth: window.innerWidth,
    fields: [
      'windDirection',
      'windSpeed',
      'humidity',
      'dewpoint',
      'forecastedDay',
      'forecastedPrecipitation',
      'chancePrecipitationDay',
      'chancePrecipitationNight',
      'forecastDayCondition',
      'forecastDayConditionIcon',
      'forecastNightCondition',
      'forecastNightConditionIcon',
      'forecastHighTemperature',
      'forecastLowTemperature',
      'windchill',
    ]
  })

  // Merge 0 and 1 together (current and forecast for today)
  // Odd, but had to filter out useless items. In testing,
  // the 6th day of forecast is mostly null values.
  const weather = [
    { ...data[1], ...stripNullProps(data[0]) },
    ...data.slice(2).filter(item => item.forecastDayCondition)
  ]
  // Create a "day" prop
  weather.map((row, idx) => row.day = addDays(new Date(), idx))
  fetchBids()
  return weather
}

// https://cencommodities.agricharts.com/inc/cashbids/cashbids-json.php
const fetchBids = async () => {
  try {
    const res = await fetch('https://cencommodities.agricharts.com/inc/cashbids/cashbids-json.php', {
      method: 'get',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
    })

    if (res.status >= 500) {
      this.onError(new Error(`API server error. ${res.status}`))
      return false;
    }
    const json = await res.json()
    const bids = parseBids(json)
    return bids
  } catch (error) {
    console.error(error)
    return false
  }
}


const parseBids = dataIn => {

  /*
  const commodities = dataIn.commodities.map(cmdty => cmdty.name)

  const locations = dataIn.locations.map(loc => ({
    name: loc.name,
    city: loc.city,
    state: loc.state,
  }))
  */
 
 //const cashbids = dataIn?.bids[0]?.cashbids || []
  let bids = dataIn?.bids || []
  let cashbids = []
  for (let item of bids) {
    cashbids = [ ...cashbids, ...item.cashbids]
  }
  //console.log('cashbids', cashbids)

  /*
  let cashbid = {
    cmdty: {
      name: 'Corn',
      symbol: 'ZCZ20',
    },
    location: 'Stanberry',
    month: 'Jun',
    basis: -0.10,
  }
  */

 bids = []
 for (const cashbid of cashbids) {
    const baseBid = {
      cmdty: {
        name: cashbid.name,
        symbol: cashbid.symbol,
      },
      basis: cashbid.basis,
      price: cashbid.cashprice
    }
    const locBids = cashbid.locations.map(loc => ({
      ...baseBid,
      location: loc.name,
    }))
    const months = monthsFromRange(cashbid.delivery_start, cashbid.delivery_end)
    for (const month of months) {
      const monthBids = locBids.map(bid => ({
        ...bid,
        month,
      }))
      bids.push(...monthBids)
    }
  }
  return bids
}

const monthsFromRange = (start, end) => {
  const endDt = parse(end, 'MM/dd/yyyy', new Date())
  let startDt = parse(start, 'MM/dd/yyyy', new Date())
  const months = []
  while (endDt > startDt) {
    months.push(format(startDt, 'MMM'))
    startDt = addMonths(startDt, 1)
  }
  return months
}

const degreeSymbol = val => {
  return val ? val.replace('&deg;', '\u00B0') : val
}

const USDA_LOGO = '/img/USDA_logo_sm.png'

const fmtTs = (ts) => {
  if (!ts) { return '' }
  const dt = parseISO(ts)
  return format(dt, 'MMM dd hh:mm b')
}

const cmdtyFromCode = code => {
  return {
    ZC: 'corn',
    ZS: 'soybean',
    KE: 'hard red wheat'
  }[code]
}

const monthFromCode = code => {
  return {
    F: 'Jan',
    G: 'Feb',
    H: 'Mar',
    J: 'Apr',
    K: 'May',
    M: 'Jun',
    N: 'Jul',
    Q: 'Aug',
    U: 'Sep',
    V: 'Oct',
    X: 'Nov',
    Z: 'Dec',
  }[code]
}

const cmdtyFromSymbol = symbol => {
  const cmdty = cmdtyFromCode(symbol.slice(0,2))
  const month = monthFromCode(symbol.slice(symbol.length - 3, symbol.length - 2))
  const year = symbol.slice(symbol.length - 2)
  return { cmdty, month, year }
}

const px = price => {
  //return (price / 100).toLocaleString('en', { style: 'currency', currency: 'USD' })
  return (price / 100).toLocaleString('en', { maximumFractionDigits: 3 })
}

export {
  fetchQuotes,
  fetchBids,
  fetchNews,
  fetchNewsItem,
  fetchWeather,
  degreeSymbol,
  DEFAULT_ZIPCODE,
  USDA_LOGO,
  fmtTs,
  cmdtyFromSymbol,
  px,
}


/*

Notes for Troy

Please find your 30-day Trial API Key below.

847747ba8b8a93c2bb934807ae2948db

1 - For pricing, billing, and query limits please contact your salesperson cc'd on this email.
2 - Your Trial Query Limits: 1,000 Queries per hour / 10,000 Queries per day / 200,000 per month
3 - You can check your enabled APIs, Exchanges, and Query Counts here in the getAccountDetails endpoint:

http://ondemand.websol.barchart.com/getAccountDetails.json?clientkey=847747ba8b8a93c2bb934807ae2948db

4 - Below is all Documentation for each of your enabled APIs and Example queries using your API Key: 847747ba8b8a93c2bb934807ae2948db



------------

getNews API:

getNews API Example Query:
http://ondemand.websol.barchart.com/getNews.json?apikey=847747ba8b8a93c2bb934807ae2948db&sources=NASS,USDA,SETZ,CMDTY_AGLITE,CMDTY_AG&displayType=full

getNews API Documentation:
http://barchartondemand.com/api/getNews

------------

getNewsSources API:

getNewsSources API Example Query:
http://ondemand.websol.barchart.com/getNewsSources.json?apikey=847747ba8b8a93c2bb934807ae2948db

getNewsSources API Documentation:
http://www.barchartondemand.com/api/getNewsSources

------------

getNewsCategories API:

getNewsCategories API Example Query:
http://ondemand.websol.barchart.com/getNewsCategories.json?apikey=847747ba8b8a93c2bb934807ae2948db&sources=USDA

getNewsCategories API Documentation:
http://www.barchartondemand.com/api/getNewsCategories

------------

getQuote API:

getQuote API Example Query:
http://ondemand.websol.barchart.com/getQuote.json?apikey=847747ba8b8a93c2bb934807ae2948db&symbols=ZCZ20,ZS*1

getQuote API Documentation:
http://barchartondemand.com/api/getQuote

-------------

getSymbolLookup API (for a symbol lookup tool):

getSymbolLookup API Example:
http://ondemand.websol.barchart.com/getSymbolLookUp.json?apikey=847747ba8b8a93c2bb9348307ae2948db&keyword=corn&limit=50

getSymbolLookup API Documentation:
http://barchartondemand.com/api/getSymbolLookUp



getWeather API Example Query:
http://ondemand.websol.barchart.com/getWeather.json?apikey=847747ba8b8a93c2bb934807ae2948db&zipCode=65683

getWeather API Documentation:
http://barchartondemand.com/api/getWeather

*/