import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { WeatherContext } from './Context/Weather'
import { degreeSymbol } from '../Barchart'


const WeatherCurrent = ({ zipCode }) => {

  const weatherCtx = useContext(WeatherContext)
  useEffect(() => {
    weatherCtx.getWeather(zipCode)
  }, [weatherCtx, zipCode])
  const weather = weatherCtx.weather[0]

  if (!weather) { return <div /> }

  return (
    <div className="text-center">
      <h2 className="text-xl text-sage">Current</h2>
      <img className="w-full h-16 object-none object-center" src={weather.currentConditionIcon} alt={weather.currentCondition} />
      <div className="font-bold text-2xl">
        {degreeSymbol(weather.currentTemperature)}
      </div>
      {weather.feelsLike !== weather.currentTemperature &&
        <div className="pl-2 font-normal text-base text-gray-600">
          feels like {degreeSymbol(weather.feelsLike)}
        </div>}
      <div className="mt-2">
        <Link to={`/radar/${weather.zipCode}`} className="bg-green hover-gold text-white font-bold py-1 px-4 rounded-md">
          radar map
      </Link>
      </div>
    </div>
  )
}

export default WeatherCurrent

/*
{
chancePrecipitationDay: null
chancePrecipitationNight: null
currentCondition: "Clear"
currentConditionIcon: "https://shared.websol.barchart.com/images/weather/sunicon.jpg"
currentPressure: "29.82 in. Hg"
currentTemperature: "79&deg;F"
dewpoint: "73&deg;F"
feelsLike: "82&deg;F"
: null
forecastDayCondition: null
forecastDayConditionIcon: null
forecastHighTemperature: null
forecastLowTemperature: null
forecastNightCondition: null
forecastNightConditionIcon: null
humidity: "82%"
location: "Shawnee Mission, Kansas"
mapRegion: null
mapType: "localRadarAnimation"
mapUrl: "https://shared.websol.barchart.com/weather/weathermap.php/interrad?zipcode=66223&width=560&height=440"
weatherType: "CC"
windDirection: "Southwest"
windSpeed: "4 mph"
windchill: null
zipCode: "66223"
}
*/