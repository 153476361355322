import React, { useContext, useEffect } from 'react'
import { WeatherContext } from './Context/Weather'


const WeatherRadar = ({ history, match }) => {

  const { zipCode } = match.params
  const weatherCtx = useContext(WeatherContext)
  useEffect(() => {
    weatherCtx.getWeather(zipCode)
  }, [weatherCtx, zipCode])
  const weather = weatherCtx.weather[0]

  if (!weather) { return <div /> }

  return (
    <div className="w-full h-full">
      <div className="h-12 p-2 flex justify-end bg-gray-200">
        <div className="flex-auto md:text-xl">
          Weather Radar for <span className="font-bold">{weather.zipCode}</span>
        </div>
        <button className="bg-green hover-gold text-white font-bold py-1 px-4 rounded-md" onClick={history.goBack}>
          <svg width="24" height="24" className="inline">
            <rect width="24" height="24" fill="none" rx="0" ry="0" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.5858 4.41418C12.5858 3.52328 11.5086 3.07711 10.8787 3.70708L3.29289 11.2929C2.90237 11.6834 2.90237 12.3166 3.29289 12.7071L10.8787 20.2929C11.5086 20.9228 12.5858 20.4767 12.5858 19.5858V16H20C20.5523 16 21 15.5523 21 15V8.99997C21 8.44769 20.5523 7.99997 20 7.99997H12.5858V4.41418Z" fill="#ffffff" />
          </svg> Back
     		</button>
      </div>

      <img src={weather.mapUrl} alt={`weather map for ${weather.zipCode}`} title={`weather map for ${weather.zipCode}`} />
    </div>
  )
}

export default WeatherRadar
