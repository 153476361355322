import React, { useState } from 'react'
import QuotesCmdty from './QuotesCmdty'
import BidsCmdty from './BidsCmdty'


const Cmdty = ({ cmdty }) => {

  const [tabState, setTabState] = useState('quotes')


  return (
    <div className="mt-10">
      <div className="block">
        <div className="border-b border-gray-200">
          <nav className="flex -mb-px">


            <Tab label="Quotes" icon="people" onClick={() => setTabState('quotes')} active={tabState === 'quotes'} />
            <Tab label="Bids" icon="people" onClick={() => setTabState('bids')} active={tabState === 'bids'} />

            <div className="px-2 sm:px-4 text-lg md:text-2xl font-bold">{cmdty}</div>

          </nav>
        </div>

        <div className="border border-sage">

          {tabState === 'quotes' && <QuotesCmdty key={cmdty} cmdty={cmdty} />}
          {tabState === 'bids' && <BidsCmdty key={cmdty} cmdty={cmdty} />}

        </div>

      </div>
    </div>
  )

}

export default Cmdty

const Tab = ({ label, icon, onClick, active }) => {
  return (
    <div onClick={onClick} className={`ml-3 px-3 py-2 font-medium text-sm leading-5 rounded-md rounded-b-none ${active ? 'text-white' : 'text-gray-700'} ${active ? 'bg-sage' : 'bg-gray-300 hover:bg-gray-400'} focus:outline-none focus:text-sage focus:bg-sage`} aria-current="page">
      {label}
    </div>
  )
}
 