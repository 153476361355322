import React, { useContext, useEffect } from 'react'
import { NewsContext } from '../Barchart/Context/News'
import NewsItemPreview from './NewsItemPreview'

const News = ({ limit }) => {

  const newsCtx = useContext(NewsContext)
  useEffect(() => {
    newsCtx.getNews()
  }, [newsCtx])
  const { news } = newsCtx

  if (!news.length) {
    return <div className="text-center p-16">News is loading...</div>
  }

  return (
    <div className="mt-4">
      {news.map(item => <NewsItemPreview key={item.newsID} item={item} />)}
    </div>
  )
}

export default News
