import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop'
import { QuotesProvider } from './Barchart/Context/Quotes'
import { BidsProvider } from './Barchart/Context/Bids'
import { NewsProvider } from './Barchart/Context/News'
import { WeatherProvider } from './Barchart/Context/Weather'
import Header from './Header'
import Footer from './Footer'
import Home from './Home'
import NotFound from './NotFound'
import NewsItemDetail from './Barchart/NewsItemDetail'
import Grain from './Grain'
import Feed from './Feed'
import CropInsurance from './CropInsurance'
import WeatherRadar from './Barchart/WeatherRadar'
import News from './News'
import About from './About'
import Alerts from './Alerts'

// Paths that should not include Header & Footer
const standAlonePaths = [
  '/radar'
]
const standAlone = path => {
  if (path.length === 1) { return false }
  const matches = standAlonePaths.filter(item => path.indexOf(item) === 0)
  return matches.length > 0
}

const App = ({ location: { pathname } }) => {
  return (
    <ScrollToTop>
      <QuotesProvider>
        <BidsProvider>
          <NewsProvider>
            <WeatherProvider>
              {!standAlone(pathname) && <Header />}
              <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/news/:slug' component={NewsItemDetail} />
                <Route exact path='/grain' component={Grain} />
                <Route exact path='/feed' component={Feed} />
                <Route exact path='/crop-insurance' component={CropInsurance} />
                <Route exact path='/radar/:zipCode' component={WeatherRadar} />
                <Route exact path='/news' component={News} />
                <Route exact path='/about' component={About} />
                <Route exact path='/alerts' component={Alerts} />
                <Route component={NotFound} />
              </Switch>
              {!standAlone(pathname) && <Footer />}
            </WeatherProvider>
          </NewsProvider>
        </BidsProvider>
      </QuotesProvider>
    </ScrollToTop>
  );
}

export default withRouter(App);
