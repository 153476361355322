import React, {Fragment} from 'react'
import {Link} from 'react-router-dom'
import Weather from './Barchart/Weather'

var fimgStyle = {
  backgroundImage: `url("/img/field.jpg")`,
  maxWidth: 500,
  minWidth: 500,
  marginTop: -50,
}

var imgAccent1 = {
  backgroundImage: `url("/img/img-accent-1.svg")`,
  backgroundSize: 150,
}

const Home = () => {
  return (
    <Fragment>
      <div className="flex-none md:flex items-stretch hero mt-6 text-white">
        <div className="pl-10 md:px-20 lg:pr-0 xl:pl-40 flex-grow center bg-green py-8">
          <h2 className="text-3xl">Rooted in Agriculture</h2>
          <p>
            For over 30 years, we have been serving you in the agricultural
            industry. We are dedicated to providing you the best service,
            products, and knowledge.
          </p>
          <Link
            to="/grain"
            className="inline-block bg-gold hover:bg-sage mt-4 text-white font-bold py-3 px-4 rounded-lg">
            Live Quotes &amp; Current Bids
          </Link>
        </div>
        <div
          className="image-wrapper-a hidden lg:inline-block"
          style={fimgStyle}>
          <img src="/img/img-over-a.svg" alt="" />
        </div>
        <div className="flex-none right w-20 md:w-20  xl:w-40 hidden lg:inline-block"></div>
      </div>
      <div className="content home px-10 md:px-20 xl:px-40 mt-8 flex-none md:flex justify-between">
        <div className="item flex-none md:flex-0-1 w-full md:w-1/3 md:pr-4">
          <Link to="/grain" className="">
            <img
              className="w-full hidden md:block rounded-lg rounded-b-none"
              src="/img/grain-2.jpg"
              alt=""
            />
            <div
              className="img-accent-1 bg-right-top bg-no-repeat text-white p-4 bg-green bg-cover"
              style={imgAccent1}>
              Grain Services
            </div>
          </Link>
        </div>

        <div className="item flex-none md:flex-0-1 w-full md:w-1/3 mt-4 md:mt-0 space-x-4 md:px-2">
          <Link to="/feed" className="">
            <img
              className="w-full hidden md:block rounded-lg rounded-b-none"
              src="/img/feed-3b.jpg"
              alt=""
            />
            <div
              className="img-accent-1 bg-right-top bg-no-repeat text-white p-4 bg-green"
              style={imgAccent1}>
              Feed
            </div>
          </Link>
        </div>

        <div className="item flex-none md:flex-0-1 w-full md:w-1/3 mt-4 md:mt-0 md:pl-4">
          <Link to="/crop-insurance" className="">
            <img
              className="w-full hidden md:block rounded-lg rounded-b-none"
              src="/img/crop-insurance.jpg"
              alt=""
            />
            <div
              className="img-accent-1 bg-right-top bg-no-repeat text-white p-4 bg-green"
              style={imgAccent1}>
              Crop Insurance
            </div>
          </Link>
        </div>
      </div>

      <div className="px-10 md:px-20 xl:px-40 py-8">
        <h2 className="text-green text-3xl mt-4 mb-3 border-b">
          Our passion runs deep
        </h2>
        <p>
          Our logo, name, and products might be different but our passion to
          serve you continues. We will provide you with great avenues and
          strategies for your grain. We have invested in the best feed equipment
          and structures to support your livestock. Agriculture is our roots and
          what we know.{' '}
          <span className="italic font-semibold">This is our home</span>.
        </p>
      </div>

      <Weather />

      <div className="cta px-20 text-center flex-none md:flex justify-center bg-light mt-6 mb-3 pt-6 pb-6">
        <Link
          to="/grain"
          className="inline-block bg-green hover:bg-sage text-white font-bold py-3 px-4 rounded-lg">
          Grain Prices &amp; Bids
        </Link>
        <h3 className="m-3">Live quotes and current local bids</h3>
      </div>

      <div className="flex px-10 md:px-20 xl:px-40 py-8">
        <div className="flex-1">
          <h2 className="text-green text-3xl mt-4 mb-3 border-b">
            Stanberry, MO
          </h2>
          <p>105 S High St</p>
          <p>(660) 783-2167</p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:info@cencommodities.com">
              info@cencommodities.com
            </a>
            <Link
              to="/grain"
              className="mt-4 inline-block text-green font-bold">
              Grain Prices &amp; Bids
            </Link>
          </p>
        </div>

        <div className="flex-1 mr-4">
          <img
            className="w-full hidden md:block"
            src="/img/location_stanberry.jpg"
            alt=""
          />
        </div>

        <div className="flex-1">
          <h2 className="text-green text-3xl mt-4 mb-3 border-b">St Joe, MO</h2>
          <p>1901 S 6th St</p>
          <p>(816) 232-8555</p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:info@cencommodities.com">
              info@cencommodities.com
            </a>
          </p>
          <Link to="/grain" className="mt-4 inline-block text-green font-bold">
            Grain Prices &amp; Bids
          </Link>
        </div>

        <div className="flex-1">
          <img
            className="w-full hidden md:block"
            src="/img/location_stjoe.jpg"
            alt=""
          />
        </div>
      </div>
    </Fragment>
  )
}

export default Home
