import React, { useReducer, createContext } from "react"
import { fetchNews } from '../'

const initialState = {
  news: [],
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_DATA':
      return {
        ...state,
        loading: false,
        news: action.data,
      }

    case 'FETCH_STARTING':
      return {
        ...state,
        loading: true,
      }

    default:
      return state
  }
}

const Context = createContext()

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    news,
    loading,
  } = state

  state.getNews = async (limit) => {
    let data = news
    if (!loading && !news.length) {
      dispatch({
        type: 'FETCH_STARTING',
      })
      const data = await fetchNews(limit)
      dispatch({
        type: 'LOAD_DATA',
        data,
      })
    }
    return data
  }

  return (
    <Context.Provider value={{
      ...state,
    }}>
      {children}
    </Context.Provider>
  )
}

export {
  Provider as NewsProvider,
  Context as NewsContext,
}