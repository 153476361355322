import React, { Fragment } from 'react'
import PageHeader from './PageHeader'

const About = () => {
  return (
    <Fragment>
      <PageHeader title="About Us" />
      <div className="flex lg:pr-40">
        <div className="left w-full lg:w-2/3">
          <div className="px-10 md:px-20 xl:px-40 text-gray-700">
            <p className="mt-4">
              Our logo, name, and products might be different but our passion to serve you continues.
              For over 30 years, we have been serving you in the agricultural industry.
              We are dedicated to providing you the best service, products, and knowledge.
            </p>

            <p className="mt-4">
              <a target="_blank" rel="noopener noreferrer" href="mailto:info@cencommodities.com" className="inline-block bg-green hover:bg-sage text-white font-bold py-3 px-4 rounded-lg">
                Contact Us
             </a>
            </p>

            <h2 className="text-green text-3xl mt-6 mb-3 border-b">
              The Team
            </h2>

            <Contact
              name="Bryan Wright"
              title="Stanberry Location Manager"
              email="bryan.wright@cencommodities.com"
              phone="660-783-2167"
            />

            <Contact
              name="Shane Stockton"
              title="St. Joseph Location Manager"
              email="shane.stockton@cencommodities.com"
              phone="816-232-8535"
            />

            <Contact
              name="Kevin Picknick"
              title="Grain Merchant"
              email="kevin.picknick@cencommodities.com"
              phone={["816-922-9643", "816-927-2272"]}
            />

            <Contact
              name="Austin Walker"
              title="Feed Sales /Crop Insurance Agent"
              email="austin.walker@cencommodities.com"
              phone="660-783-2167"
            />

            <Contact
              name="Gregg Sherwood"
              title="Feed Specialist"
              email="gregg.sherwood@cencommdoties.com"
              phone="660-783-2167"
            />

            <Contact
              name="Tracey Wright"
              title="Feed / Grain / Crop Insurance"
              email="tracey.wright@cencommodities.com"
              phone="660-783-2700"
            />

            <Contact
              name="Scott Gage"
              title="Feed / Grain / Crop Insurance"
              email="scott.gage@cencommodities.com"
              phone="660-783-2700"
            />

            <Contact
              name="Brad Gage"
              title="Feed / Grain / Crop Insurance"
              email="brad.gage@cencommodities.com"
              phone="660-783-2700"
            />

            <Contact
              name="Makenzie Gage"
              title="Feed / Grain / Crop Insurance"
              email="makenzie.gage@cencommodities.com"
              phone="660-783-2700"
            />

            <Contact
              name="Sandi Walker"
              title="Crop Insurance Agent"
              email="sandi.walker@coreins.llc"
              phone="660-783-2700"
            />

            <Contact
              name="Barb Ratliff"
              title="Crop Insurance Assistant"
              email="barb.ratliff@cencommodities.com"
              phone="660-783-2700"
            />

          </div>
          <div className="pl-40 flex w-1/2 mb-4 hidden lg:flex">
            <img className="mt-8 flex-1-0 " src="/img/about-2.jpg" alt="Central Commodities Facility" />
            <img className="mt-8 ml-2 mr-2 flex-1-0" src="/img/about-3.jpg" alt="Central Commodities Facility" />
          </div>
        </div>
        <div className="hidden lg:block right w-1/3 mt-4">
          <img className="w-full rounded-lg rounded-b-none" src="/img/team.jpg" alt="Central Commodities Facility" />
        </div>
      </div>
    </Fragment>
  )
}

export default About


const Contact = ({ name, title, email, phone }) => {
  if (!Array.isArray(phone)) {
    phone = [ phone ]
  }
  return (
    <div className="my-4 p-4 border-1 border-gray-400 rounded-lg shadow">
      <div className="font-bold">
        {name}
      </div>
      <div className="">
        {title}
      </div>
      <div className="">
        <a target="_blank" rel="noopener noreferrer" href={`mailto:${email}`} className="relative -mr-px flex-1 inline-flex items-center justify-center">
          <svg className="w-5 h-5 mr-3 hidden sm:block text-gray-600" viewBox="0 0 20 20" fill="currentColor">
            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
          </svg>
          <span className="underline">{email}</span>
        </a>
      </div>

      {phone.map(num => ( <Phone num={num} /> ))}
      
    </div>
  )
}

const Phone = ({ num }) => (
  <div className="">
    <a target="_blank" rel="noopener noreferrer" href={`tel:${num}`} className="relative -mr-px flex-1 inline-flex items-center justify-center">
      <svg className="w-5 h-5 mr-3 hidden sm:block text-gray-600" viewBox="0 0 20 20" fill="currentColor">
        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
      </svg>
      <span className="underline">{num}</span>
    </a>
  </div>
)