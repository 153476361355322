import React, { useContext } from 'react'
import { WeatherContext } from './Context/Weather'
import format from 'date-fns/format'
import { degreeSymbol } from '../Barchart'

const WeatherForecastDay = ({ todayPlus }) => {

  const weatherCtx = useContext(WeatherContext)
  const weather = weatherCtx.weather[todayPlus]

  if (!weather) { return <div /> }

  const dayUI = () => {
    const day = format(weather.day, 'EEE')
    if (day === format(new Date(), 'EEE')) {
      return 'Today'
    }
    return day
  }

  return (
    <div className="text-center">
      <h2 className="text-xl text-sage">{dayUI()}</h2>
      <img className="w-full h-16 object-none object-center" src={weather.forecastDayConditionIcon} alt={weather.forecastDayCondition} />
      <div className="font-bold text-lg">
        <span>
          {degreeSymbol(weather.forecastHighTemperature)}
        </span>
        <span className="pl-2 text-base text-gray-600">
          {degreeSymbol(weather.forecastLowTemperature)}
        </span>
      </div>
      <div>
        <span className="font-bold">
          {weather.chancePrecipitationDay}
        </span> precip
      </div>
      {weather.forecastedPrecipitation && <div>
        {weather.forcastedPrecipitation} expected
      </div>}
      {weather.windSpeed && <div>
        Wind <span className="font-bold">
          {degreeSymbol(weather.windSpeed)}
        </span> 
      </div>}
    </div>
  )
}

export default WeatherForecastDay

/*
{
chancePrecipitationDay: null
chancePrecipitationNight: null
currentCondition: "Clear"
currentConditionIcon: "https://shared.websol.barchart.com/images/weather/sunicon.jpg"
currentPressure: "29.82 in. Hg"
currentTemperature: "79&deg;F"
dewpoint: "73&deg;F"
feelsLike: "82&deg;F"
: null
forecastDayCondition: null
forecastDayConditionIcon: null
forecastHighTemperature: null
forecastLowTemperature: null
forecastNightCondition: null
forecastNightConditionIcon: null
humidity: "82%"
location: "Shawnee Mission, Kansas"
mapRegion: null
mapType: "localRadarAnimation"
mapUrl: "https://shared.websol.barchart.com/weather/weathermap.php/interrad?zipcode=66223&width=560&height=440"
weatherType: "CC"
windDirection: "Southwest"
windSpeed: "4 mph"
windchill: null
zipCode: "66223"
}
*/