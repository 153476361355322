import React, { useContext } from 'react'
import { WeatherContext } from '../Barchart/Context/Weather'
import WeatherCurrent from './WeatherCurrent'
import WeatherForecastDay from './WeatherForecastDay'

const Weather = ({ zipCode }) => {

  const weatherCtx = useContext(WeatherContext)
  const { weather } = weatherCtx
  const current = weather[0]

  return (
    <div className="px-20 xl:px-40">
      {current ?
        <h1 className="text-xl text-green">
          {current.location}
          <span className="pl-4 text-gray-600 text-lg">
            {current.zipCode}
          </span>
        </h1>
        :
        <h1 className="my-16">
          Weather is loading...
        </h1>
      }
      <div className="flex flex-wrap">
        <div className="m-1 p-4 flex-auto border-4 border-sage">
          <WeatherCurrent zipCode={zipCode} />
        </div>

        {current &&
          <div className="m-1 p-4 flex-auto border-2 ">
            <WeatherForecastDay todayPlus={0} />
          </div>
        }
        {weather.length > 1 &&
          <div className="m-1 p-4 flex-auto border-2 ">
            <WeatherForecastDay todayPlus={1} />
          </div>
        }
        {weather.length > 2 &&
          <div className="m-1 p-4 flex-auto border-2 ">
            <WeatherForecastDay todayPlus={2} />
          </div>
        }
        {weather.length > 3 &&
          <div className="m-1 p-4 flex-auto border-2 ">
            <WeatherForecastDay todayPlus={3} />
          </div>
        }
        {weather.length > 4 &&
          <div className="m-1 p-4 flex-auto border-2 ">
            <WeatherForecastDay todayPlus={4} />
          </div>
        }
      </div>
    </div>
  )
}

export default Weather

/*

// TODO: Jeff  HOLD OFF ON THIS FOR NOW

I need to learn more about what weaether data is available and
the difference between CURRENT weather and FORECAST weather
and nuances in how we need to display it.


The outer <div> above needs to be replaced with whatever beaufiful
design you want to create for how we display weather.


The object looks like this:

{
chancePrecipitationDay: null
chancePrecipitationNight: null
currentCondition: "Clear"
currentConditionIcon: "https://shared.websol.barchart.com/images/weather/sunicon.jpg"
currentPressure: "29.82 in. Hg"
currentTemperature: "79&deg;F"
dewpoint: "73&deg;F"
feelsLike: "82&deg;F"
forcastedPrecipitation: null
forecastDayCondition: null
forecastDayConditionIcon: null
forecastHighTemperature: null
forecastLowTemperature: null
forecastNightCondition: null
forecastNightConditionIcon: null
humidity: "82%"
location: "Shawnee Mission, Kansas"
mapRegion: null
mapType: "localRadarAnimation"
mapUrl: "https://shared.websol.barchart.com/weather/weathermap.php/interrad?zipcode=66223&width=560&height=440"
weatherType: "CC"
windDirection: "Southwest"
windSpeed: "4 mph"
windchill: null
zipCode: "66223"
}

*/