import React, { Fragment, useState, useEffect } from 'react'
import PageHeader from '../PageHeader'
import { fetchNewsItem } from './'
import { USDA_LOGO, fmtTs } from './'

const NewsItemDetail = ({ history, match }) => {

	const { slug } = match.params
	const storyId = slug.split('-')[0]

	const [story, setStory] = useState({})

	const getStory = async () => {
		const data = await fetchNewsItem(storyId)
		setStory(data)
	}

	useEffect(() => {
		getStory()
	}, [])

	return (
		<Fragment>

			<PageHeader title={story.headline} />

			<div className="px-4 md:px-10 lg:px-20 xl:px-40 my-6 md:my-10">

				{!story && <div>loading...</div>}

				{story &&
					<Fragment>
						<button className="bg-green hover-gold text-white font-bold py-2 mb-4 px-4 rounded-md" onClick={history.goBack}>
							<svg width="24" height="24" className="inline">
								<rect width="24" height="24" fill="none" rx="0" ry="0" />
								<path fillRule="evenodd" clipRule="evenodd" d="M12.5858 4.41418C12.5858 3.52328 11.5086 3.07711 10.8787 3.70708L3.29289 11.2929C2.90237 11.6834 2.90237 12.3166 3.29289 12.7071L10.8787 20.2929C11.5086 20.9228 12.5858 20.4767 12.5858 19.5858V16H20C20.5523 16 21 15.5523 21 15V8.99997C21 8.44769 20.5523 7.99997 20 7.99997H12.5858V4.41418Z" fill="#ffffff" />
							</svg> Back
        		</button>
						<div className="flex-none md:flex items-center text-dark my-4">
							{story.source === 'USDA' ?
								<img className="usda mr-2" src={USDA_LOGO} alt="USDA logo" style={{ height: 20 }} />
								:
								<span className="block md:inline text-sage">{story.source}</span>
							}
							<span className="block md:inline">
								{story.source !== 'USDA' && <span className="hidden md:inline text-sage mx-2">/</span>}
								<span>
									{fmtTs(story.timestamp)}
								</span>
							</span>
						</div>
						{!story.fullText && story.headlineURL &&
							<a className="underline text-dark" target="_blank" rel="noopener noreferrer" href={story.headlineURL}>{story.preview}</a>
						}
						{story.fullText &&
							<pre className="whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: story.fullText }}></pre>
						}
					</Fragment>
				}

			</div>

		</Fragment>
	)
}

export default NewsItemDetail

//<h1 className="text-2xl text-green pb-4 font-bold">{story.headline}</h1>
