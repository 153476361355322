import React, {Fragment} from 'react'
import {Link, withRouter} from 'react-router-dom'

const Header = () => {
  var mobileMenuStyle = {
    position: 'absolute',
    left: '50%',
    marginLeft: '-25px',
    height: 25,
  }
  var mobileSpanStyle = {
    position: 'absolute',
    width: 50,
    height: 5,
    borderTop: '3px solid white',
  }
  var logoStyle = {
    maxWidth: 200,
  }
  function toggleMenu() {
    // toggle class
    var mobileMenu = document.querySelector('#mobile-menu')
    mobileMenu.classList.toggle('hidden')
  }

  return (
    <Fragment>
      <div id="site-header" className="flex-none md:flex">
        <div className="md:px-10 xl:pl-40 px-16 md:px-0 md:pr-0 w-full md:w-auto md:align-self md:flex-start">
          <Link
            to="/"
            className="p-4 border-b md:border-0 block md:inline md:mr-6 leading-6 font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
            <img
              className="py-4"
              src="/img/logo.png"
              alt="Central Commodities logo"
              style={logoStyle}
            />
          </Link>
        </div>
        <div className="block md:hidden bg-sage text-white text-center py-2 h-16">
          <div className="py-2" onClick={toggleMenu} style={mobileMenuStyle}>
            <span className="h-4 min-w-full" style={mobileSpanStyle}></span>
            <span
              className="mt-4 h-4 min-w-full"
              style={mobileSpanStyle}></span>
            <span
              className="mt-8 h-4 min-w-full"
              style={mobileSpanStyle}></span>
          </div>
        </div>
        <div
          id="mobile-menu"
          className="hidden md:block md:flex items-stretch content-center flex-grow-1 align-self flex-end">
          <nav
            className="flex-none md:flex content-center pt-4 pb-3"
            style={{minHeight: 65}}>
            <div
              id="main-menu"
              className="text-center md:text-left block md:block flex-none md:flex content-center text-med pt-0 md:pt-12 md:ml-8">
              <Link
                onClick={toggleMenu}
                to="/"
                className="p-4 md:px-2 border-b md:border-0 block md:inline md:mr-1 leading-6 font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
                Home
              </Link>

              <Link
                onClick={toggleMenu}
                to="/grain"
                className="p-4 md:px-2 border-b md:border-0 block md:inline md:mr-1 leading-6 font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
                Grain
              </Link>

              <Link
                onClick={toggleMenu}
                to="/feed"
                className="p-4 md:px-2 border-b md:border-0 block md:inline md:mr-1 leading-6 font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
                Feed
              </Link>

              <Link
                onClick={toggleMenu}
                to="/crop-insurance"
                title="Important reminders"
                className="p-4 md:px-2 border-b md:border-0 block md:inline md:mr-1 leading-6 font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
                Crop Insurance
                <strong class="relative inline-flex items-center rounded border border-gray-200 px-2.5 py-1.5 text-xs font-medium text-white">
                  <span class="absolute -mt-6 h-5 w-5 rounded-full bg-gold flex justify-center items-center items">
                    <span>4</span>
                  </span>
                </strong>
              </Link>

              <Link
                onClick={toggleMenu}
                to="/news"
                className="md:ml-3 p-4 md:px-2 border-b md:border-0 block md:inline md:mr-1 leading-6 font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
                News
              </Link>

              <Link
                onClick={toggleMenu}
                to="/about"
                className="p-4 md:px-2 border-b md:border-0 block md:inline md:mr-1 leading-6 font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
                About
              </Link>

              {/*
              <a target="_blank" rel="noopener noreferrer" href="mailto:info@cencommodities.com" className="p-4 block md:inline md:mr-1 leading-6 font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">Contact</a>
              */}
            </div>
          </nav>
        </div>
      </div>
    </Fragment>
  )
}

export default withRouter(Header)
