import React, { Fragment } from 'react'
import { cmdtyFromSymbol, px } from '../Barchart'


const QuoteRow = ({ quote }) => {
  
  const cmdty = cmdtyFromSymbol(quote.symbol)

  return (
    <Fragment>
      <tr className="hover-bg-light">
        <td className="border-b px-4 py-2">{`${cmdty.month} ${cmdty.year}`}</td>
        <td className="border-b px-4 py-2">{px(quote.lastPrice)}</td>
        <td className="border-b px-4 py-2">{px(quote.netChange)}</td>
        <td className="border-b px-4 py-2">{px(quote.high)}</td>
        <td className="border-b px-4 py-2">{px(quote.low)}</td>
      </tr>
    </Fragment>
  )
}

export default QuoteRow

/*

// TODO: Jeff, Troy

We need to know from the client what data to include

{
    "symbol":"ZCZ20",
    "name":"Corn",
    "dayCode":"I",
    "serverTimestamp":"2020-06-19T18:00:22-05:00",
    "mode":"i",
    "lastPrice":345.25,
    "tradeTimestamp":"2020-06-19T15:59:46-05:00",
    "netChange":2.5,
    "percentChange":0.73,
    "unitCode":"-1",
    "open":342,
    "high":345.75,
    "low":339,
    "close":345.25,
    "numTrades":0,
    "dollarVolume":0,
    "flag":"s",
    "volume":102897,
    "previousVolume":99923
},

*/