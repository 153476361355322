import React, { Fragment } from 'react'
import PageHeader from './PageHeader'

const Alerts = () => {

  const btnClass = 'm-4 inline-block bg-gold hover:bg-sage mt-4 text-white font-bold py-3 px-4 rounded-lg'

  return (
    <Fragment>
      <PageHeader title="Sign up for alerts" />
      <div className="py-8">
        <div className="">
          <div className="px-10 md:px-20 xl:px-40 text-gray-700">
            <p className="py-4">
              Be in the know. Receive price and other alerts in your email or on your phone.
            </p>
            <a
              target="_blank"
              href="https://cencommodities.agricharts.com/account/register.php"
              className={btnClass}
            >
              Register for Alerts
            </a>

            <a
              target="_blank"
              href="https://cencommodities.agricharts.com/account/login.php"
              className='m-4 inline-block bg-green hover:bg-sage text-white font-bold py-3 px-4 rounded-lg'
            >
              Login to Alerts
            </a>

            <div>

              <p className="pt-4">
                In order to configure your alerts, you first register for an account.
                Your account lets you tells us how you want to be notified.
              </p>

              <p className="pt-4">
                If you have already registered, use the <span className="font-bold">Login</span> button to access your account.
                Otherwise click the <span className="font-bold">Register</span> button to create your account.
              </p>

            </div>

          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Alerts
