import React, { Fragment } from 'react'
import { cmdtyFromSymbol, px } from '../Barchart'


const BidRow = ({ bid, hideMonth }) => {
  
  const cmdty = cmdtyFromSymbol(bid.cmdty.symbol)

  return (
    <Fragment>
      <tr className="hover-bg-light">
        <td className="border-b px-4 py-2">{hideMonth ? '' : bid.month}</td>
        <td className="border-b px-4 py-2">{bid.location}</td>
        <td className="border-b px-4 py-2">{bid.price}</td>
        <td className="border-b px-4 py-2">{px(bid.basis)}</td>
        <td className="border-b px-4 py-2">{`${cmdty.month} ${cmdty.year} `}</td>
      </tr>
    </Fragment>
  )
}

export default BidRow
