import React, { Fragment } from 'react'
import PageHeader from './PageHeader'

const CropInsurance = () => {
  return (
    <Fragment>
      <PageHeader title="Crop Insurance" />

      <div className="flex-none px-8 xl:flex xl:pr-40 xl:pl-40">
        <div className="left w-full xl:w-2/3 mb-8">
          <h2 className="text-green text-3xl mt-4 mb-3 border-b">
            Providing the Crop Insurance Knowledge and Service You Deserve,
            Since 1999
          </h2>
          <p>
            See us to secure your Crop, Livestock, Pasture coverage through RMA
            Programs in Missouri, Kansas, Iowa and Nebraska.
          </p>

          <div className="my-6 lg:pr-8">

            <div className="my-2 p-4 bg-yellow-200 border border-green rounded-md">
              <div className="font-bold">2023 Crop Insurance Prices</div>

              <br />
              <div className="flex-none md:flex py-1 border-b">
                <div className="flex-1"></div>
                <div className="flex-1">Spring Price</div>
                <div className="flex-1">Harvest Price</div>
              </div>
              <div className="flex-none md:flex py-1 border-b">
                <div className="flex-1">Corn</div>
                <div className="flex-1">$5.91</div>
                <div className="flex-1">$4.88</div>
              </div>
              <div className="flex-none md:flex py-1 border-b">
                <div className="flex-1">Soybeans</div>
                <div className="flex-1">$13.76</div>
                <div className="flex-1">$12.84</div>
              </div>
              <div className="flex-none md:flex py-1 border-b">
                <div className="flex-1">Grain Sorghum</div>
                <div className="flex-1">$5.84</div>
                <div className="flex-1">$4.83</div>
              </div>
              <div className="flex-none md:flex py-1 border-b">
                <div className="flex-1">Wheat</div>
                <div className="flex-1">$8.45</div>
                <div className="flex-1">$6.83</div>
              </div>
            </div>
          </div>

          <h2 className="text-green text-2xl mt-4 mb-3">Office Locations</h2>

          <div className="flex-none md:flex border-b py-2">
            <div className="flex-1">Stanberry (Home Office)</div>
            <div className="flex-1">117 N Alanthus Ave</div>
            <div className="flex-1">(660) 783-2700</div>
          </div>

          <div className="flex-none md:flex border-b py-2">
            <div className="flex-1">Stanberry Central Commodities</div>
            <div className="flex-1">105 S High St</div>
            <div className="flex-1">(660) 783-2167</div>
          </div>

          <div className="flex-none md:flex border-b py-2">
            <div className="flex-1">Bethany Office</div>
            <div className="flex-1">22208 E US Hwy 13</div>
            <div className="flex-1">(660) 425-4815</div>
          </div>

          <div className="flex-none md:flex border-b py-2">
            <div className="flex-1">Albany Office</div>
            <div className="flex-1">105 W Wood St</div>
            <div className="flex-1">(816) 351-2796</div>
          </div>

          <div className="flex-none md:flex border-b py-2">
            <div className="flex-1">St Joe Central Commodities</div>
            <div className="flex-1">1901 S 6th St</div>
            <div className="flex-1">(816) 232-8555</div>
          </div>

          <div className="flex-none md:flex border-b py-2">
            <div className="flex-1">Princeton Office</div>
            <div className="flex-1">Jct 136 & 65</div>
            <div className="flex-1">(660) 748-8789</div>
          </div>

          <div className="flex-none md:flex border-b py-2">
            <div className="flex-1">Atha Bros. Agency Office</div>
            <div className="flex-1">8870 SE Grain Bin Rd</div>
            <div className="flex-1">(816) 676-9647</div>
          </div>

          <div className="flex-none md:flex border-b py-2">
            <div className="flex-1">Missouri Valley Office</div>
            <div className="flex-1">117 E Erie St</div>
            <div className="flex-1">(531) 222-6952</div>
          </div>

          <h2 className="text-green text-2xl mt-4 mb-3">Agents</h2>

          <div className="flex-none md:flex border-b py-2">
            <div className="flex-1">Tracey G Wright</div>
            <div className="flex-1">(660) 425-4815</div>
            <div className="flex-1">
              Stanberry Home Office / Bethany on Wednesdays
            </div>
          </div>

          <div className="flex-none md:flex border-b py-2">
            <div className="flex-1">Sandi Walker</div>
            <div className="flex-1">(816) 351-2796</div>
            <div className="flex-1">
              Stanberry Home Office / Albany on Thursdays
            </div>
          </div>

          <div className="flex-none md:flex border-b py-2">
            <div className="flex-1">Makenzie Gage</div>
            <div className="flex-1">(660) 373-2453</div>
            <div className="flex-1">
              Stanberry Home Office / St. Joe on Fridays
            </div>
          </div>

          <div className="flex-none md:flex border-b py-2">
            <div className="flex-1">Austin Walker</div>
            <div className="flex-1">(816) 385-7446</div>
            <div className="flex-1">Stanberry Central Commodities Location</div>
          </div>

          <div className="flex-none md:flex border-b py-2">
            <div className="flex-1">Marcie Davis</div>
            <div className="flex-1">(660) 748-8789</div>
            <div className="flex-1">Princeton Office</div>
          </div>

          <div className="flex-none md:flex border-b py-2">
            <div className="flex-1">Ashley Shisler</div>
            <div className="flex-1">(660) 254-3876</div>
            <div className="flex-1">Stanberry Home Office/Burlington Junction</div>
          </div>

          <div className="flex-none md:flex border-b py-2">
            <div className="flex-1">Colby Crockett Chisam</div>
            <div className="flex-1">(816) 752-3405</div>
            <div className="flex-1">Atha Bros. Agency Office</div>
          </div>

          <div className="flex-none md:flex border-b py-2">
            <div className="flex-1">Dylan Grant</div>
            <div className="flex-1">(816) 673-6280</div>
            <div className="flex-1">Atha Bros. Agency Office</div>
          </div>

          <div className="flex-none md:flex border-b py-2">
            <div className="flex-1">Justin Jacobsen</div>
            <div className="flex-1">(531) 222-6952</div>
            <div className="flex-1">Missouri Valley Office</div>
          </div>
        </div>



        <div className="hidden xl:block right w-1/3 mt-4">
          <img
            className="w-full rounded-lg rounded-b-none"
            src="/img/crop-insurance.jpg"
            alt="Crop Insurance for a flooded field"
          />
        </div>
      </div >
      <div className="px-12 xl:pr-40 xl:pl-40 mb-12"> {/* container */}
        <h2 className="text-green text-2xl mt-4 mb-3">Crop Insurance Deadlines</h2>
        <div className="flex flex-row border-b">
          <div className="sm:w-full md:w-1/3"></div>
          <div className="hidden md:flex sm:w-full md:w-2/3">
            <div className="sm:w-full md:w-1/4">Corn</div>
            <div className="sm:w-full md:w-1/4">Soybeans</div>
            <div className="sm:w-full md:w-1/4">Wheat</div>
            <div className="sm:w-full md:w-1/4">
              Pasture Rangeland< br />
              Forage (PRF)
            </div>
          </div>
        </div>
        {/* row */}
        <div className="mb-4 md:mb-0 md:flex md:flex-row border-b py-2">
          <div className="mb-4 md:mb-0 sm:w-full sm:shrink-0 md:w-1/3 text-lg text-green md:text-base">
            Sales Closing Date
          </div>
          <div className="block w-full md:w-2/3">
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Corn</div>
            <div className="inline-block w-1/2 md:w-1/4">3/15</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Soybeans</div>
            <div className="inline-block w-1/2 md:w-1/4">3/15</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Wheat</div>
            <div className="inline-block w-1/2 md:w-1/4">9/30</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">(PRF)</div>
            <div className="inline-block w-1/2 md:w-1/4">12/01</div>
          </div>
        </div>
        {/* row */}
        <div className="mb-4 md:mb-0 md:flex md:flex-row border-b py-2">
          <div className="mb-4 md:mb-0 sm:w-full sm:shrink-0 md:w-1/3 text-lg text-green md:text-base">
            Purchase/Cancellation/< br />Transfer/Change Date
          </div>
          <div className="block w-full md:w-2/3">
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Corn</div>
            <div className="inline-block w-1/2 md:w-1/4">3/15</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Soybeans</div>
            <div className="inline-block w-1/2 md:w-1/4">3/15</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Wheat</div>
            <div className="inline-block w-1/2 md:w-1/4">9/30</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">(PRF)</div>
            <div className="inline-block w-1/2 md:w-1/4">12/01</div>
          </div>
        </div>
        {/* row */}
        <div className="mb-4 md:mb-0 md:flex md:flex-row border-b py-2">
          <div className="mb-4 md:mb-0 sm:w-full sm:shrink-0 md:w-1/3 text-lg text-green md:text-base">
            Projected Price Announced
          </div>
          <div className="block w-full md:w-2/3">
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Corn</div>
            <div className="inline-block w-1/2 md:w-1/4">3/05</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Soybeans</div>
            <div className="inline-block w-1/2 md:w-1/4">3/05</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Wheat</div>
            <div className="inline-block w-1/2 md:w-1/4">9/20</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">(PRF)</div>
            <div className="inline-block w-1/2 md:w-1/4">-</div>
          </div>
        </div>
        {/* row */}
        <div className="mb-4 md:mb-0 md:flex md:flex-row border-b py-2">
          <div className="mb-4 md:mb-0 sm:w-full sm:shrink-0 md:w-1/3 text-lg text-green md:text-base">
            Initial Planting Date
          </div>
          <div className="block w-full md:w-2/3">
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Corn</div>
            <div className="inline-block w-1/2 md:w-1/4">*</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Soybeans</div>
            <div className="inline-block w-1/2 md:w-1/4">*</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Wheat</div>
            <div className="inline-block w-1/2 md:w-1/4">N/A</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">(PRF)</div>
            <div className="inline-block w-1/2 md:w-1/4">-</div>
          </div>
        </div>
        {/* row */}
        <div className="mb-4 md:mb-0 md:flex md:flex-row border-b py-2">
          <div className="mb-4 md:mb-0 sm:w-full sm:shrink-0 md:w-1/3 text-lg text-green md:text-base">
            Final Planting Date < br />(For Full Guarantee)
          </div>
          <div className="block w-full md:w-2/3">
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Corn</div>
            <div className="inline-block w-1/2 md:w-1/4">*</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Soybeans</div>
            <div className="inline-block w-1/2 md:w-1/4">*</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Wheat</div>
            <div className="inline-block w-1/2 md:w-1/4">*</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">(PRF)</div>
            <div className="inline-block w-1/2 md:w-1/4">-</div>
          </div>
        </div>
        {/* row */}
        <div className="mb-4 md:mb-0 md:flex md:flex-row border-b py-2">
          <div className="mb-4 md:mb-0 sm:w-full sm:shrink-0 md:w-1/3 text-lg text-green md:text-base">
            Production Reporting Due Date
          </div>
          <div className="block w-full md:w-2/3">
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Corn</div>
            <div className="inline-block w-1/2 md:w-1/4">4/29</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Soybeans</div>
            <div className="inline-block w-1/2 md:w-1/4">4/29</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Wheat</div>
            <div className="inline-block w-1/2 md:w-1/4">11/14</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">(PRF)</div>
            <div className="inline-block w-1/2 md:w-1/4">-</div>
          </div>
        </div>
        {/* row */}
        <div className="mb-4 md:mb-0 md:flex md:flex-row border-b py-2">
          <div className="mb-4 md:mb-0 sm:w-full sm:shrink-0 md:w-1/3 text-lg text-green md:text-base">
            Acreage Report Deadline
          </div>
          <div className="block w-full md:w-2/3">
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Corn</div>
            <div className="inline-block w-1/2 md:w-1/4">7/15</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Soybeans</div>
            <div className="inline-block w-1/2 md:w-1/4">7/15</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Wheat</div>
            <div className="inline-block w-1/2 md:w-1/4">11/15</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">(PRF)</div>
            <div className="inline-block w-1/2 md:w-1/4">12/01</div>
          </div>
        </div>
        {/* row */}
        <div className="mb-4 md:mb-0 md:flex md:flex-row border-b py-2">
          <div className="mb-4 md:mb-0 sm:w-full sm:shrink-0 md:w-1/3 text-lg text-green md:text-base">
            Premium Billing Date
          </div>
          <div className="block w-full md:w-2/3">
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Corn</div>
            <div className="inline-block w-1/2 md:w-1/4">8/15</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Soybeans</div>
            <div className="inline-block w-1/2 md:w-1/4">8/15</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Wheat</div>
            <div className="inline-block w-1/2 md:w-1/4">7/01</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">(PRF)</div>
            <div className="inline-block w-1/2 md:w-1/4">9/01</div>
          </div>
        </div>
        {/* row */}
        <div className="mb-4 md:mb-0 md:flex md:flex-row border-b py-2">
          <div className="mb-4 md:mb-0 sm:w-full sm:shrink-0 md:w-1/3 text-lg text-green md:text-base">
            Harvest Price Announced
          </div>
          <div className="block w-full md:w-2/3">
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Corn</div>
            <div className="inline-block w-1/2 md:w-1/4">11/05</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Soybeans</div>
            <div className="inline-block w-1/2 md:w-1/4">11/05</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Wheat</div>
            <div className="inline-block w-1/2 md:w-1/4">8/05</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">(PRF)</div>
            <div className="inline-block w-1/2 md:w-1/4">-</div>
          </div>
        </div>
        {/* row */}
        <div className="mb-4 md:mb-0 md:flex md:flex-row border-b py-2">
          <div className="mb-4 md:mb-0 sm:w-full sm:shrink-0 md:w-1/3 text-lg text-green md:text-base">
            End of Insurance Date
          </div>
          <div className="block w-full md:w-2/3">
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Corn</div>
            <div className="inline-block w-1/2 md:w-1/4">12/10</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Soybeans</div>
            <div className="inline-block w-1/2 md:w-1/4">12/10</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Wheat</div>
            <div className="inline-block w-1/2 md:w-1/4">10/31</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">(PRF)</div>
            <div className="inline-block w-1/2 md:w-1/4">12/31</div>
          </div>
        </div>
        {/* row */}
        <div className="mb-4 md:mb-0 md:flex md:flex-row border-b py-2">
          <div className="mb-4 md:mb-0 sm:w-full sm:shrink-0 md:w-1/3 text-lg text-green md:text-base">
            Crop Hail Insurance Carryover Coverage Terminates (Varies by Insurance Company)
          </div>
          <div className="block w-full md:w-2/3">
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Corn</div>
            <div className="inline-block w-1/2 md:w-1/4">*</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Soybeans</div>
            <div className="inline-block w-1/2 md:w-1/4">*</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Wheat</div>
            <div className="inline-block w-1/2 md:w-1/4">*</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">(PRF)</div>
            <div className="inline-block w-1/2 md:w-1/4">*</div>
          </div>
        </div>
        {/* row */}
        <div className="mb-4 md:mb-0 md:flex md:flex-row border-b py-2">
          <div className="mb-4 md:mb-0 sm:w-full sm:shrink-0 md:w-1/3 text-lg text-green md:text-base">
            Crop Hail Insurance Discount Date (Varies by Insurance Company)
          </div>
          <div className="block w-full md:w-2/3">
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Corn</div>
            <div className="inline-block w-1/2 md:w-1/4">*</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Soybeans</div>
            <div className="inline-block w-1/2 md:w-1/4">*</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">Wheat</div>
            <div className="inline-block w-1/2 md:w-1/4">*</div>
            <div className="md:hidden inline-block w-1/2 md:w-1/4 font-bold">(PRF)</div>
            <div className="inline-block w-1/2 md:w-1/4">*</div>
          </div>
        </div>
        {/* row */}
        <div className="mb-4 md:mb-0 md:flex md:flex-row py-2">
          <div className="mb-4 md:mb-0 w-full sm:shrink-0 text-lg text-grey md:text-base italic">
            * Contact your crop insurance agent
          </div>

        </div>

      </div>
    </Fragment >
  )
}

export default CropInsurance