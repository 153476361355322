import React, { Fragment } from 'react'
import PageHeader from './PageHeader'
import News from './Barchart/News'

const ExamplePageWithNews = () => {

  return (
    <Fragment>
      <PageHeader title="News" />
      <News limit={10} />
    </Fragment>
  )
}

export default ExamplePageWithNews