import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

export default () => (
  <Fragment>
    <div className="bg-sage h-12">

    </div>
    <div className="footer flex:none md:flex bg-light p-4 md:p-8 mt-2 text-center">
      <div className="footer-logo flex-none md:flex-1 mb-4 p-4 md:p-8 ">
        <img className="w-2/3 m-auto" alt="Central Commodities logo" src="/img/logo.png" />
      </div>
      <div className="flex-1 p-4 md:p-8 text-center">
        <h5 className="text-xl font-semibold">Central Commodities</h5>
        <p className="leading-normal">117 N Alanthus Ave.</p>
        <p>Stanberry, MO 64889</p>
        <p>(660) 783-2700</p>
        <p><a className="underline text-green hover:text-sage" href="mailto:info@cencommodities.com">info@cencommodities.com</a></p>
        <p><Link className="underline text-green hover:text-sage" to="/alerts">Price alerts</Link></p>
      </div>

      <div className="flex-1 p-4 md:p-8 text-center">
        <h5 className="text-xl font-semibold">Grain Elevators</h5>
        <div className="">

          <h3 className="block text-green text-xl text-center">St Joe, MO</h3>
          <p>1901 S 6th St | (816) 232-8555</p>

          <h3 className="mt-2 block text-green text-xl text-center">Stanberry, MO</h3>
          <p>105 S High St | (660) 783-2167</p>

        </div>
      </div>
    </div>
    <div className="copyright h4 p-3 mb-2 text-center text-med">
      <span className="block md:inline">
        © Copyright Central Commodities, All Rights Reserved
      </span>
      {/* <span className="hidden md:inline mx-4">|</span>
      <span className="block md:inline">
        <Link to="/" className="underline text-dark">Privacy Policy</Link><span className="md:inline mx-2 md:mx-4">|</span><Link to="/" className="underline text-dark">Disclaimers</Link>
      </span> */}

    </div>
  </Fragment >
)