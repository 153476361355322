import React, { Fragment } from 'react'
import PageHeader from './PageHeader'

const Feed = () => {
  return (
    <Fragment>
      <PageHeader title="FULL Service Feed" />
      <div className="flex lg:pr-40">
        <div className="left w-full lg:w-2/3">
          <div className="px-10 md:px-20 xl:px-40 text-gray-700">
            <h2 className="text-green text-3xl mt-4 mb-3 border-b">
              Nutrition backed by science
        </h2>

            <p className="mt-4">
              We offer nutritional advice backed by a PhD Purina Nutritionist.
              We can come to your farm for consultation to ensure a balanced diet
              for your livestock. With our access to the <span className="font-semibold">Purina research farm</span>, we are continually looking at products that will enhance our producer’s productivity.
        </p>

            <p className="mt-4">

            </p>

            <p className="mt-4">
              Central Commodities LLC is a full-service feed company offering
          both <span className="font-semibold">bulk</span> and <span className="font-semibold">bagged</span> products
          to the producer.
        </p>

            <ul className="ml-8 list-disc">
              <li className="py-2">
                Custom commodity and
                commercial protein mixes delivered to your operation
          </li>
              <li className="py-2">
                We provide a service
                to purchase raw commodities and deliver straight to your farm.
          </li>
              <li className="py-2">
                The full line of Purina minerals, protein supplements, and balancers
          </li>
              <li className="py-2">
                Bulk Creep feeds and commodities blends to fit your operation
          </li>
            </ul>

            <p className="my-4">
              We also carry gates, fencing, tags, and vet supplies.
        </p>
          </div>
          <div className="pl-40 flex w-1/2 mb-4 hidden lg:flex">
            <img className="mt-8 flex-1-0 " src="/img/feed-3.jpg" alt="Central Commodities Feed Facility" />
            <img className="mt-8 ml-2 mr-2 flex-1-0" src="/img/feed.jpg" alt="Central Commodities Feed Facility" />
          </div>
        </div>
        <div className="hidden lg:block right w-1/3 mt-4">
          <img className="w-full rounded-lg rounded-b-none" src="/img/feed-2.jpg" alt="Cattle Feed Facility" />
        </div>
      </div>
    </Fragment>
  )
}

export default Feed