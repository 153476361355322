import React, { useContext } from 'react'
import { BidsContext } from './Context/Bids'
import BidRow from './BidRow'

const tempTrans = cmdty => ({
  'Corn': 'Corn',
  'Soybean': 'Soybeans',
  'Hard Red Wheat': 'Wheat',
})[cmdty]

const BidsCmdty = ({ cmdty }) => {

  cmdty = tempTrans(cmdty)

  const bidsCtx = useContext(BidsContext)
  const bids = bidsCtx.bids.filter(b => b.cmdty.name === cmdty)
  let prevMonth = ''
  let hideMonth = false

  return (
    <div>
      {/* <h1 className="mt-4 text-xl">{cmdty}</h1> */}
      <table className="table-auto w-full">
        <thead className="bg-sage text-white">
          <tr className="text-left">
            <th className="px-4 py-2">Delivery</th>
            <th className="px-4 py-2">Location</th>
            <th className="px-4 py-2">Cash Price</th>
            <th className="px-4 py-2">Basis</th>
            <th className="px-4 py-2">Futures Month</th>
          </tr>
        </thead>
        <tbody>

          {!bids.length &&
            <tr><td colSpan="5" className="p-10">
              No bid data for {cmdty}
            </td></tr>
          }

          {bids.map((bid, idx) => {
            if (bid.month === prevMonth) {
              hideMonth = true
            } else {
              hideMonth = false
            }
            prevMonth = bid.month
            return <BidRow key={`${bid.month}-${idx}`} bid={bid} hideMonth={hideMonth} />
          })}
        </tbody>
      </table>
    </div>
  )
}

export default BidsCmdty
