import React, { useReducer, createContext } from "react"
import { fetchBids } from '..'


const initialState = {
  bids: [],
  loading: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_DATA':
      return {
        ...state,
        loading: false,
        bids: action.data || [],
      }

    case 'FETCH_STARTING':
      return {
        ...state,
        loading: true,
      }

    default:
      return state
  }
}

const Context = createContext()

const Provider = ({ children }) => {
  
  const [state, dispatch] = useReducer(reducer, initialState)
  let refreshTimer = null

  const {
    bids,
    loading,
  } = state

  state.getBids = async (forceRefresh = false) => {

    if (!loading && (!bids.length || forceRefresh)) {
      dispatch({
        type: 'FETCH_STARTING',
      })

      const data = await fetchBids()
      dispatch({
        type: 'LOAD_DATA',
        data,
      })

    }
  }

  state.startPolling = () => {
    if (!refreshTimer) {
      refreshTimer = 1 // Need something set immediately
      const refreshData = () => {
        state.getBids(true)
        refreshTimer = window.setTimeout(refreshData, 30000)
      }
      refreshData()
    }
  }

  state.stopPolling = () => {
    if (refreshTimer) {
      window.clearTimeout(refreshTimer)
    }
  }

  return (
    <Context.Provider value={{
      ...state,
    }}>
      {children}
    </Context.Provider>
  )
}

export {
  Provider as BidsProvider,
  Context as BidsContext,
}